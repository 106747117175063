import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";

const DataProtectInfo = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          // don't show the menu on this page
          headerClassName: "site-header--menu-none",
          headerButton: (
            <>
              <Link
                to="/#"
                className="element btn bg-gradient-1 btn-text-white header-btn rounded-5"
              >
                Zum Erstgespräch
              </Link>
            </>
          ),
        }}
      >
        <div className="pt-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Datenschutzhinweise</h2>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">Einleitung</h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Zweck dieser Datenschutzhinweise ist, Sie gemäß den
                      Vorgaben der Datenschutz-Grundverordnung („DSGVO“) und des
                      Datenschutzgesetzes („DSG“) darüber zu informieren, welche
                      personenbezogenen Daten, zu welchem Zweck, auf welcher
                      Grundlage verarbeitet werden und welche Rechte Sie als
                      Besucher:in der Website www.konzierge.at („Website“) haben.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">Verantwortlicher</h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Datenschutzrechtlich verantwortlich für die Verarbeitung
                      Ihrer personenbezogenen Daten auf dieser Website ist die{" "}
                      <br />
                      <div className="my-5">
                        Thesmo Legal Tech Solutions GmbH, FN 610462p
                        <br />
                        Schönbrunner Straße 20/8
                        <br /> 1050 Wien, Österreich
                      </div>
                      Nachstehend erfahren Sie mehr zu unseren
                      Verarbeitungsvorgängen. Sollten Sie Fragen zum Datenschutz
                      haben, senden Sie uns bitte eine E-Mail an
                      office@konzierge.at.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">Kontaktformular</h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Wir stellen auf unserer Website ein Kontaktformular zur
                      Verfügung, damit Sie über einen sicheren Kanal mit uns in
                      Kontakt treten können, um mehr über unsere
                      Dienstleistungen zu erfahren, spezielle Wünsche
                      einzureichen, Kooperationsvorschläge zu senden etc. Wenn
                      Sie dieses Kontaktformular verwenden, geben Sie folgende
                      Daten ein:
                      <br />
                      - Vor- und Nachname
                      <br />
                      - Firma/Unternehmensbezeichnung
                      <br />
                      - Kontaktdaten (E-Mail-Adresse)
                      <br />
                      Diese Angaben verwenden wir im Falle einer
                      Kooperationsanfrage gemäß Art 6 Abs 1 lit b DSGVO bzw im
                      Falle sonstiger Anliegen gemäß Art 6 Abs 1 lit f DSGVO
                      dazu, Ihre Anfrage rasch zu erledigen und zu beantworten.
                      Diese Daten speichern wir maximal
                      12 Monate nach Erledigung Ihrer Anfrage, sofern kein
                      Vertrag zustande kommt oder die Ausnahmen in
                      Art 17 Abs 3 DSGVO greifen (zB rechtliche Verpflichtung,
                      Verteidigung von Rechtsansprüchen etc).
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Tracking (deaktiviert)
                    </h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Auf unserer Website verwenden wir Trackingtools wie Google
                      Analytics, um den Traffic auf unserer Seite zu messen und
                      Ihr Erlebnis auf der Website zu optimieren. Diese Tools
                      messen unter anderem:
                      <br />
                      - Verhalten auf der Website, z.B.: Welche Seiten werden
                      aufgerufen? Wo wird geklickt?
                      <br />
                      - Technische Informationen über den Websitebesucher, z.B.:
                      Desktop oder mobiles Gerät, Größe des Bildschirms,
                      Betriebssystem
                      <br />
                      - Quelle des Besuchs z.B.: Wurde eine von uns
                      ausgesteuerte Marketingaktivität benutzt, um auf unsere
                      Website zu gelangen? Welche Suchbegriffe wurden verwendet?
                      Von welcher Website wurde der Besucher auf unsere Website
                      geleitet?
                      <br />
                      - Tracking IDs, durch die der Website Besucher nicht
                      persönlich identifizierbar ist, die es uns aber
                      ermöglichen, Besucher zu unterscheiden. Dadurch können
                      z.B. Analysen erstellt werden, wie oft ein Besucher in
                      einem bestimmten Zeitraum wiederkehrt.
                      <br />- NICHT durch Trackingtools gespeichert werden
                      Informationen, durch die Benutzer direkt persönlich
                      identifizierbar sind, wie z.B. Angaben in Formular-Feldern
                      auf der Website.
                      <br />
                      Diese Angaben verwenden wir gemäß Art 6 Abs 1 lit a DSGVO
                      sowohl dazu, aggregierte Auswertungen über die Benutzung
                      unserer Website zu erstellen als auch um unsere Website
                      und Marketingaktivitäten zu optimieren. Diese Daten
                      speichern wir maximal 36 Monate nach Besuch auf der
                      Website.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Google Analytics (deaktiviert)
                    </h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Die Verwendung von Google Analytics hängt von Ihrer
                      Einwilligung ab. Erteilen Sie keine Einwilligung, werden
                      keine Google Analytics Cookies gesetzt. Ihre Einwilligung
                      bedeutet, dass sie damit einverstanden sind, dass Google
                      Analytics Cookies gesetzt und Daten wie zB Ihre IP-Adresse
                      an Server von Google (in der Regel in den USA oder in
                      anderen Staaten außerhalb der EU) übertragen und dort
                      gespeichert werden dürfen. Sofern Sie in die
                      Cookie-Nutzung eingewilligt haben, verwenden wir auf
                      manchen Bereichen unserer Website auch Google Analytics,
                      einen Webanalysedienst der Google Inc. („Google“). Dieses
                      Analysetool verwendet Cookies, um Besucherverhalten auf
                      unserer Website in aggregierter Form zu erfassen. Die
                      folgenden Informationen werden von Google Analytics
                      gesammelt: Browser, Gerätetyp, Gerätemodell, Land,
                      Service-Provider, Bildschirmauflösung (auf mobilem Gerät),
                      Verweildauer auf der Website, Sprache, Betriebssystem,
                      besuchte Seiten auf der Website. Google bereitet obige
                      Informationen auf und wir nutzen diese, um die Nutzung
                      unserer Website durch Nutzer auszuwerten. Auch wird Google
                      diese Informationen gegebenenfalls an Dritte übertragen,
                      sofern dies gesetzlich vorgeschrieben ist oder Dritte
                      diese Daten im Auftrag von Google verarbeiten. Falls Sie
                      nicht wollen, dass irgendeine Website Ihre Aktivitäten
                      über Google Analytics aufzeichnet, können Sie sich unter
                      http://tools.google.com/dlpage/gaoptout ein entsprechendes
                      Browser-Plugin herunterladen und installieren.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Herkunft der Daten
                    </h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Ihre Nutzerdaten werden entweder von Ihnen selbst durch
                      Verwendung der auf der Website befindlichen Formularen an
                      uns übermittelt. Die Trackingdaten, die bei Ihrem Besuch
                      unserer Webseite anfallen, erheben wir automatisiert und
                      in der Regel entweder auf Basis Ihrer Eingabe oder über
                      Cookies.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">Weitergabe von Daten</h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Wir geben Ihre personenbezogenen Daten nur in
                      gerechtfertigten Fällen an Dritte weiter, wobei wir die
                      Grundsätze der Notwendigkeit, Datenminimierung,
                      Transparenz und Datenintegrität wahren. Folgende
                      Kategorien von Empfängern kommen in Betracht:
                      <br />
                      - IT-Dienstleister, die unser Content Management System
                      und die IT-Struktur für den Betrieb der Website zur
                      Verfügung stellen;
                      <br />
                      - Anbieter von Analyse-Tools (Google Analytics) zum Zweck
                      der Marktanalyse und Performancesteigerung; (deaktiviert)
                      <br />
                      - Gerichte, Behörden, Staatsanwaltschaft und andere
                      öffentlich-rechtliche Institutionen im Rahmen unserer
                      gesetzlichen Pflichten;
                      <br />
                      - Rechtsanwälte, und Inkassobüros zur Durchsetzung und
                      Verteidigung von Rechtsansprüchen sowie
                      Forderungseintreibung;
                      <br />- Sonstige Einrichtungen der Streitbeilegung;
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Datenübermittlung in Drittländer
                    </h5>
                    <p className="font-size-5 heading-default-color mb-0">
                    Sollte eine Datenübermittlung an Stellen in Ländern außerhalb 
                    des Europäischen Wirtschaftsraums (EWR) erforderlich sein, 
                    stellen wir sicher, dass die Daten gemäß den gesetzlichen 
                    Vorgaben geschützt sind, zB durch den Abschluss von 
                    Standardvertragsklauseln oder durch eine 
                    Angemessenheitsentscheidung der Europäischen Kommission.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Schutz Ihrer Daten
                    </h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Wir und unsere IT-Dienstleister (Hosting) treffen
                      angemessene technische und organisatorische
                      Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten
                      gegen unbeabsichtigte oder unrechtmäßige Löschung,
                      Veränderung oder gegen Verlust und gegen unberechtigte
                      Weitergabe oder unberechtigten Zugriff zu schützen.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Datenschutzrechte
                    </h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Gemäß Art 14 ff EU-Datenschutzgrundverordnung (DSGVO)
                      haben Sie ein Recht auf Auskunft, Berichtigung, Löschung,
                      Einschränkung der Verarbeitung und Datenübertragbarkeit
                      sowie ein Widerrufsrecht. Außerdem können Sie Ihre
                      erteilten Einwilligungen jederzeit und ohne Grund für die
                      Zukunft zu widerrufen. Bei Fragen können Sie sich
                      jederzeit an uns unter office@konzierge.at wenden. Sie
                      haben auch das Recht, sich mit einer Beschwerde an die
                      österreichische Datenschutzbehörde (Barichgasse 40-42,
                      1030 Wien) zu wenden.
                    </p>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">
                      Fragen und weitere Informationen
                    </h5>
                    <p className="font-size-5 heading-default-color mb-0">
                      Für Fragen und weitere Informationen über die
                      Datenverarbeitung über unsere Website verwenden Sie bitte
                      folgende E-Mail Adresse: office@konzierge.at.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default DataProtectInfo;
